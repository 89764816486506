import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import logo from "../Assets/Logo/logo-border.png"; // Replace with the actual path to your logo

// Styled component for the rotating logo
const RotatingLogo = styled("img")({
  width: 160, // Increased size of the logo
  height: 160,
  animation: "rotate 2s linear infinite", // Rotate animation
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
});

// Styled component for the text in the center
const CenterText = styled(Box)({
  position: "absolute",
  fontSize: "18px", // Decreased text size
  fontWeight: "bold",
  color: "white", // Text color, adjust as needed
  textAlign: "center",
});

export default function CustomLoader() {
  const [typedText, setTypedText] = useState("");
  const text = "3 60"; // The text to be typed out
  const typingSpeed = 200; // Speed of typing in milliseconds

  useEffect(() => {
    let currentIndex = 0;
    let isDeleting = false;

    const type = () => {
      setTypedText((prev) => {
        if (!isDeleting) {
          return prev + text[currentIndex]; // Add the next character
        } else {
          return prev.slice(0, prev.length - 1); // Delete the last character
        }
      });

      if (!isDeleting) {
        if (currentIndex < text.length - 1) {
          currentIndex++;
        } else {
          isDeleting = true;
        }
      } else if (isDeleting && currentIndex > 0) {
        currentIndex--;
      } else {
        isDeleting = false;
        currentIndex = 0; // Loop the text typing
      }
    };

    const typingInterval = setInterval(type, typingSpeed);

    return () => clearInterval(typingInterval); // Clean up interval on unmount
  }, [text, typingSpeed]);

  return (
    <Stack
      spacing={2}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "#000", // Change background color if needed
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <RotatingLogo src={logo} alt="Logo" />
        <CenterText>{typedText}</CenterText> {/* Display the typed text */}
      </Box>
    </Stack>
  );
}
